import React from "react";
import { Link } from "gatsby";
import SignaturePackaging_Hero from "../../../assets/img/signature-packaging.jpg";
import bags from "../../../assets/img/retail-packaging-bags.jpg";
import boxes from "../../../assets/img/retail-packaging-boxes.jpg";
import tissue_paper from "../../../assets/img/retail-packaging-tissue.jpg";
import tulle from "../../../assets/img/retail-packaging-gift-wrap.jpg";
import ribbon from "../../../assets/img/retail-packaging-ribbon.jpg";

import styles from "../style/styles.module.css";

export default function SignaturePackaging() {
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <div className={styles.content}>
          <h1>Signature Packaging</h1>
          <h2>Help retailers stand out and advertise all over town.</h2>
          <p className={styles.productParag}>Hot-selling packaging supplies.</p>
          <img
            className={styles.hero}
            src={SignaturePackaging_Hero}
            alt="Gift bags"
          />

          <p className={styles.productMargin}>
            Help your retail customers get noticed and build their brand with
            our stylish line of Signature Packaging. These stunning,
            custom-branded bags, boxes, bows, tissue and ribbon set retail
            businesses apart from competitors, attract new customers, and help
            you profit handsomely!
          </p>
          <ul>
            <li>
              Custom-branded retail packaging supplies in many colours and sizes
            </li>
            <li>Exclusive new designs each year</li>
            <li>Low minimum order quantities</li>
            <li>Large average orders with 15% margins for you</li>
          </ul>
          <hr style={{ border: "1px solid #d3dce2", width: "100%" }} />
        </div>
      </div>

      <div className={styles.row}>
        <div className={styles.content}>
          <h2>Product Category Highlights</h2>
          <div className={styles.cards}>
            <div className={styles.card}>
              <img src={bags} alt="Gift bags Image"></img>
              <Link to="/products/signature-packaging/bags/">Bags</Link>
            </div>
            <div className={styles.card}>
              <img src={boxes} alt="Boxes Image"></img>
              <Link to="/products/signature-packaging/boxes/">Boxes</Link>
            </div>
            <div className={styles.card}>
              <img src={tissue_paper} alt="Tissue paper Image"></img>
              <Link to="/products/signature-packaging/tissue-paper/">
                Tissue Paper
              </Link>
            </div>
            <div className={styles.card}>
              <img src={tulle} alt="Tulle Image"></img>
              <Link to="/products/signature-packaging/gift-wrap/tulle/">
                Tulle
              </Link>
            </div>
            <div className={styles.card}>
              <img src={ribbon} alt="Ribbon Image"></img>
              <Link to="/products/signature-packaging/ribbon/">Ribbon</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
