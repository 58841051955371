import React from "react";
import Layout from "../../../other/layout";
import SignaturePackaging from "../../../components/AC-StaticPages/products/SignaturePackaging";
import SEO from "../../../other/seo";

export default function signaturePackaging() {
  return (
    <Layout>
      <SEO title="Signature Packaging" />

      <SignaturePackaging />
    </Layout>
  );
}
